import React, { useEffect } from 'react'
import './Footer.css'
import { Link, useLocation } from 'react-router-dom';
// import logo from '../../Assets/White-Logo.png'
import logo from '../../Assets/socialConnectFullLogo.png'


const Footer = () => {
  const location = useLocation();

  // Scroll to the top on route change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]); // When location (route) changes, scroll to the top

  return (
    <>
      <div className='container-fluid footer-section'>
        <div className='row footer-container'>
          <div className='col-lg-5'>
            <div className='footer-col1'>
              <Link to="/">
                <img src={logo} className='flogo'></img>
              </Link>
              <p>Transforming Ideas into Digital Excellence. Elevate your online presence with our innovative solutions and strategic digital services.</p>
              <div className='social-media'>
                <div className='social-media-icon'>
                  <a href="https://www.facebook.com/people/Social-Connect/100094234899954/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={16} width={16} className='ticon'>
                      <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5 16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0 129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                    </svg>
                  </a>
                </div>
                <div className='social-media-icon'>
                  <a href="https://www.facebook.com/profile.php?id=100094234899954" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}>
                      <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                    </svg>
                  </a>
                </div>
                <div className='social-media-icon'>
                  <a href="https://x.com/SocialConnect07" target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-twitter" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                    </svg>
                  </a>
                </div>
                <div className='social-media-icon'>
                  <a href="https://www.instagram.com/thesocialconnect__/" target="_blank" rel="noopener noreferrer">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18">
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9 114.9-51.3 114.9-114.9-51.3-114.9-114.9-114.9zm0 188.1c-40.4 0-73.2-32.8-73.2-73.2s32.8-73.2 73.2-73.2 73.2 32.8 73.2 73.2-32.8 73.2-73.2 73.2zm146.4-194.3c0 14.9-12 27-27 27-14.9 0-27-12-27-27s12-27 27-27 27 12 27 27zm76.1 27.2c-.7-14.9-4.1-29.7-10.1-43.2-6-13.6-14.3-26.1-24.9-36.6s-23-18.9-36.6-24.9c-13.5-6-28.3-9.4-43.2-10.1-30.2-1.4-122.2-1.4-152.4 0-14.9.7-29.7 4.1-43.2 10.1-13.6 6-26.1 14.3-36.6 24.9s-18.9 23-24.9 36.6c-6 13.5-9.4 28.3-10.1 43.2-1.4 30.2-1.4 122.2 0 152.4.7 14.9 4.1 29.7 10.1 43.2 6 13.6 14.3 26.1 24.9 36.6s23 18.9 36.6 24.9c13.5 6 28.3 9.4 43.2 10.1 30.2 1.4 122.2 1.4 152.4 0 14.9-.7 29.7-4.1 43.2-10.1 13.6-6 26.1-14.3 36.6-24.9s18.9-23 24.9-36.6c6-13.5 9.4-28.3 10.1-43.2 1.4-30.2 1.4-122.2 0-152.4zm-48.7 209.6c-1.2 27.2-7.7 51.3-26.4 70-18.7 18.7-42.8 25.2-70 26.4-27.6 1.5-110.7 1.5-138.4 0-27.2-1.2-51.3-7.7-70-26.4-18.7-18.7-25.2-42.8-26.4-70-1.5-27.6-1.5-110.7 0-138.4 1.2-27.2 7.7-51.3 26.4-70 18.7-18.7 42.8-25.2 70-26.4 27.6-1.5 110.7-1.5 138.4 0 27.2 1.2 51.3 7.7 70 26.4 18.7 18.7 25.2 42.8 26.4 70 1.5 27.6 1.5 110.7 0 138.4z"/>
                   </svg>
                 </a>
              </div>
            {/*  <div className='social-media-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
aria-describedby="desc" role="img" width="18" height="18" >
  <title>Instagram</title>
  <desc>A line styled icon from Orion Icon Library.</desc>
  <path data-name="layer2"
  d="M44.122 2H19.87A17.875 17.875 0 0 0 2 19.835v24.2a17.875 17.875 0 0 0 17.87 17.834h24.252A17.875 17.875 0 0 0 62 44.034v-24.2A17.875 17.875 0 0 0 44.122 2zM55.96 44.034a11.825 11.825 0 0 1-11.838 11.812H19.87A11.825 11.825 0 0 1 8.032 44.034v-24.2A11.825 11.825 0 0 1 19.87 8.022h24.252A11.825 11.825 0 0 1 55.96 19.835zm0 0"
  fill="none" stroke="#202020" stroke-linecap="round" stroke-miterlimit="10"
  stroke-width="2" stroke-linejoin="round"></path>
  <path data-name="layer1" d="M32 16.45a15.484 15.484 0 1 0 15.514 15.484A15.519 15.519 0 0 0 32 16.45zm0 24.95a9.461 9.461 0 1 1 9.482-9.461A9.472 9.472 0 0 1 32 41.4zm19.263-24.834a3.719 3.719 0 1 1-3.719-3.711 3.714 3.714 0 0 1 3.719 3.711zm0 0"
  fill="none" stroke="#202020" stroke-linecap="round" stroke-miterlimit="10"
  stroke-width="2" stroke-linejoin="round"></path>
</svg>
</div>*/}
              </div>
              
            </div>
          </div>
          <div className='col-lg-3 col-md-4 col-sm-4 col-12'>
            <div className='footer-col2'>
              <h6>Services</h6>
              <Link to="/socialmedia" className='footer-li'>Social Media Marketing</Link>
              <Link to="/lg" className='footer-li'>Leads Generation</Link>
              <Link to="/gd" className='footer-li'>Graphic design</Link>
              <Link to="/seo" className='footer-li'>Search Engine Optimization</Link>
              <Link to="/branding" className='footer-li'>Branding</Link>
              <Link to="/webdev" className='footer-li'>Website Development</Link>
            </div>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-4 col-6 footer-col3 text-center'>
            <h6>Support</h6>
            <Link to="/contact" className='footer-li'>Contact Us</Link><br></br>
            <Link to="/faq" className='footer-li'>FAQ</Link>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-4 col-6 footer-col4 text-center'>
            <h6>Company</h6>
            <Link to="/about" className='footer-li'>About Us</Link><br></br>
           {/* <Link to="/teamcard" className='footer-li'>Our Team</Link><br></br>*/}
            <Link to="https://www.blogger.com/about/?bpli=1" className='footer-li'>Blogs</Link>
          </div>
        </div>
        <div className='line'>
          <hr></hr>
        </div>
        <div className='last-sec'>
          <div className='row last-row'>
            <div className='col-lg-8 col-md-6 col-sm-6 last-a'>
              ©2024 Social Connect IT Services All Right Reserved.
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 last-b'>
              Privacy Policy
            </div>
            <div className='col-lg-2 col-md-3 col-sm-3 last-c'>
              Terms Of Service
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Footer


import React from 'react'
import './Contact.css'
import Herosection from '../Herosection/Herosection'
import Footer from '../Footer/Footer'
import ContactDetail from '../ContactDetail/ContactDetail'
import Navbarfun from '../Navbar/Navbar'


const Contact = () => {
  return (
    <>
      <Navbarfun />
      <div className='contact_page'>
        <section>
          <Herosection heading="Contact us" subheading="Start the conversation to establish a good relationship and business." />
          <section>
            <ContactDetail />
          </section>
          <section>
            <div className='container-fluid footer'>
              <Footer />
            </div>
          </section>
        </section>
      </div>
    </>
  )
}

export default Contact

import React from 'react'
import Singal from '../SingalPost/SingalPost'

const Gd = () => {
  return (
    <>
      <Singal head6="Creative Ideas for  Graphic Design" head1="Graphic Design" flpara="Graphic design is a dynamic and rewarding field that combines creativity with technical skills." detail="Descriptions"  detailpara="Graphic design is a creative process that combines art and technology to communicate ideas visually. It plays a crucial role in branding, advertising, web design, and various other fields. Effective graphic design can capture attention, convey messages, and inspire action. This guide will cover six key points to help you understand and excel in graphic design." q1="Understanding the Basics of Graphic Design" q2="Mastering Design Software" q3="Developing a Strong Portfolio" q4="Staying Current with Design Trends" q5="Networking and Professional Development" q6="Building a Brand Identity" ></Singal>
    </>
  )
}

export default Gd

import React, { useEffect, useState } from 'react'
import './Herosection.css'
import AOS from "aos";
import "aos/dist/aos.css";

const Herosection = (props) => {

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <section>
        <div className='container-fluid'>
          <div className='first-section'>
            <h1 data-aos="zoom-in-down">{props.heading}</h1>
            <h5 data-aos="zoom-in-up">{props.subheading}</h5>
          </div>
        </div>
      </section>
    </>
  )
}

export default Herosection

import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Value.css'

const Value = (props) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <div className='value-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='value-first' data-aos="zoom-in-right">
                <div className='value-firstDiv'>
                  <div className='value-content'>
                    <h6 className='value-slogan'>{props.valueslogan}</h6>
                    <h1>{props.head}</h1>
                    <p>{props.para}</p>

                  </div>
                </div>
                <div className='value-arrow-top'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-up-right right-up-value" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                  </svg>
                </div>
              </div>

            </div>
            <div className='col-lg-4'>
              <div className='value-second'>
                <div className='value-second-1' data-aos="zoom-in-down">
                  <h6>{props.valueSecond1slogan}</h6>
                  <h3>{props.valueSecond1head}</h3>
                  <p>{props.valueSecond1para}</p>

                </div>
                <div className='value-second-2' data-aos="zoom-in-up">
                  <h6>{props.valueSecond2slogan}</h6>
                  <h4>{props.valueSecond2head}</h4>
                  <p>{props.valueSecond2para}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Value

import React, { useEffect, useState } from 'react'
import './Partner.css'
import desktop from '../../Assets/imac_img.png'
import boost from '../../Assets/boostImg.png'
import mobile from '../../Assets/partner_mobileimg.png'
import AOS from "aos";
import "aos/dist/aos.css";

const Partner = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <section className='container-fluid partner-section'>
        <div className='partner-head'>
          <div className='row'>
            <div className='col-lg-7 pheading'>
              <h1>Explore New Digital Frontiers with Social Connect</h1>
            </div>
            <div className='col-lg-5 ppara'>
              <p>With our proven expertise and unwavering commitment, we serve as your trusted guide, navigating the ever-changing horizons of the digital world to propel your brand towards unparalleled success.
              </p>
            </div>
          </div>
        </div>
        <div className='main-partnership-section'>
          <div className='row'>
            <div className='col-md-9'>
              <div className='p1'>
                <div className='row'>
                  <div className='col-md-8 p1-large' data-aos="zoom-in-right">
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='p1-large-a'>
                          <h4>Timely and Transparent Communication</h4>
                          <p>From project updates to strategy discussions, we keep you informed and empowered, fostering trust and driving impactful results together. </p>
                          <button className='pbtn'>Learn More</button>
                        </div>
                      </div>
                      <div className='col-sm-6 mac-section'>
                        <img src={desktop} className='pmac'></img>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='boostDiv' data-aos="fade-up"
                      data-aos-duration="3000">
                      <img src={boost} className='boost'></img>
                      <div className='boost-content'>
                        <h4>Boost Traffic & Sales</h4>
                        <p>Drive revenue growth with our targeted digital marketing strategies, maximizing both traffic and sales for your business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='ratingDiv' data-aos="fade-down-right">
                      <h1>4.9+</h1>
                      <h5>Client Ratings</h5>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill checked" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill checked" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill checked" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill checked" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill " viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>

                      </div>
                      <div className='stars'></div>
                      <p>Your victories are our greatest achievements.</p>
                    </div>
                  </div>
                  <div className='col-lg-8 p2-large' data-aos="fade-up">
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='p2-large-b'>
                          <h4>Responsive and Scalable Solutions</h4>
                          <p>Our agency provides responsive, scalable solutions, adapting to your needs and maximizing impact.
                          </p>
                          <button className='pbtn2'>Learn More</button>
                        </div>
                      </div>
                      <div className='col-sm-6 mobile-section'>
                        <img src={mobile} className='mobile'></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='p-last' data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500">
                <div className='tele-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-telephone tele" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg>
                </div>
                <h5>Premium Support</h5>
                <h3>9399668096</h3>
                <p>Our digital marketing agency offers premium support, providing personalized assistance and expertise to ensure your success in every campaign and strategy.<br></br>
                  With dedicated guidance and swift resolution, we're committed to elevating your brand to new heights.
                </p>
                <button className='callnow'>Call Now</button>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default Partner

import React, { useEffect, useState } from 'react';
import bgVideo from '../../Assets/bgVideo.mp4';
import AOS from "aos";
import "aos/dist/aos.css";
import './Home.css';
import Testimonial from '../Testimonial/Testimonial';
import Footer from '../Footer/Footer'
import Advantage from '../Advantage-section/Advantage'
import Project from '../Project/Project';
import Digital from '../DigitalFuture/Digital';
import Value from '../Value/Value';
import Counter from '../Counter/counter';
import Step from '../Step Section/Step';
import Technology from '../Technology/Technology';
import Partner from '../Partner/Partner';
import Navbarfun from '../Navbar/Navbar';


function Home() {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <Navbarfun />
      <div className='home_page'>
        <section>
          <div className='video'>
            <video autoPlay muted loop id="myVideo">
              <source src={bgVideo} type="video/mp4" />
            </video>
          </div>
          <div className='mainHero'>
            <div className='heroDiv' data-aos="zoom-in">
              <div className='slogan'>WELCOME TO SOCIAL CONNECT</div>
              <h1 data-aos="fade-up" data-aos-duration="2000">Transforming brands! <br /></h1>
              <p data-aos="zoom-out-down">Through innovative digital strategies</p>
            </div>
          </div>
        </section>
        <Digital />
        <Value valueslogan="Our Value" head="Smart Designs, Sharp Results - Choose Social Connect for Digital Excellence." para="Your Success is Our Success: When you win, we win. We measure success in smiles and happy connections, not just in charts and graphs." valueSecond1head="Our Vision" valueSecond1para="At Social Connect, our vision is to revolutionize the digital marketing landscape by fostering authentic connections between brands and their audiences." valueSecond2head="Our Mission" valueSecond2para="Our goal is simple: we want to tell your brand's story in the digital world. We're not just about numbers; we're about making your brand stand out and creating real connections." />
        <section>
          <div className='container-fluid counter'>
            <Counter />
          </div>
        </section>
        <section >
          <div className='container-fluid technology' >
            <div className='technology-content'>
              <h6 data-aos="zoom-in-down">WHAT WE OFFER</h6>
              <h1 data-aos="zoom-in-down">Where Strategy Meets Digital. Your Success Story Begins with Social Connect.</h1>
            </div>
            <Technology />
          </div>
        </section>
        <Step />
        <Partner />
        <section>
          <div className='container-fluid project'>
            <div className='project-content'>
              <h6>
                FEATURED PROJECT
              </h6>
              <h1> We Make Creative Solutions</h1>
            </div>
            <Project />
          </div>
        </section>
        <section>
          <div className='container-fluid advantage-section'>
            <Advantage />
          </div>
        </section>
        <section>
          <div className='container-fluid testimonial'>
            <Testimonial />
          </div>
        </section>
        <section>
          <div className='container-fluid footer'>
            <Footer />
          </div>
        </section>
      </ div>
    </>
  );
}

export default Home;

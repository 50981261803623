import React, { useEffect, useState } from 'react';
import './Technology.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Rightarrow from '../Rightarrow/RightArrow'
import app1 from '../../Assets/app (1).png'
import websiteIconVideo from '../../Assets/web-developer.gif'
import ssmIconVideo from '../../Assets/Social-Media-Marketing.gif'
import leadGenerationIconVideo from '../../Assets/lead-gen.gif'
import graphicDesignIconVideo from '../../Assets/flyer.gif'
import brandingIconVideo from '../../Assets/video.gif'
import seoIconVideo from '../../Assets/seo.gif'
import social from '../../Assets/social-media.png'
import g1 from '../../Assets/vector.png'
import seo from '../../Assets/seo.png'
import { Link } from 'react-router-dom';

function Technology() {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const tcard_data = [
    {
      icon: (
        <img
          src={websiteIconVideo}
          alt="Website Development"
          width="55"
          height="55"
          className="t-icon"
        />
      ),
      navigatelink: "/webdev",
      head: 'Website Development',
      text: 'From responsive design to user-friendly interfaces and robust back-end functionality, we harness the latest technologies to deliver websites.'
    },
    {
      icon: (
        <img
          src={leadGenerationIconVideo}
          alt="Leads Generation"
          width="55"
          height="55"
          className="t-icon"
        />
      ),
      navigatelink: "/lg",
      head: 'Leads Generation',
      text: 'Lead generation is a dynamic and multifaceted process that requires a strategic approach to attract, engage, and convert potential customers.'
    },
    {
      icon: (
        <img
          src={ssmIconVideo}
          alt="Social Media Marketing"
          width="55"
          height="55"
          className="t-icon"
        />
      ),
      navigatelink: "/socialmedia",
      head: 'Social Media Marketing',
      text: 'Welcome to Social Connect, your gateway to a new era of Social Media Marketing (SMM) excellence!'
    },
    {
      icon: <img src={graphicDesignIconVideo} height={55} width={55} alt="Graphic Design" />,
      navigatelink: "/gd",
      head: 'Graphic Design',
      text: 'Graphic design is a dynamic and rewarding field that combines creativity with technical skills.'
    },
    {
      icon: (
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="40"
        //   height="40"
        //   fill="currentColor"
        //   className="bi bi-stack t-icon"
        //   viewBox="0 0 16 16"
        // >
        //   <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
        //   <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
        // </svg>
        <img src={brandingIconVideo} height={55} width={55} alt="Branding" />
      ),
      navigatelink: "/branding",
      head: 'Branding',
      text: `Let us help you craft a brand that not only stands out but leaves a lasting impression in the hearts and minds of your audience.`
    },
    {
      icon: <img src={seoIconVideo} height={55} width={55} alt="SEO" />,
      navigatelink: "/seo",
      head: 'Search Engine Optimization',
      text: 'Picture your website not just being visible but thriving at the top of search engine results, effortlessly capturing the attention of your target audience.'
    }
  ];
     

  return (
    <>
      <div className='technology-section'>

        <div className='tcard-container'>
          {tcard_data.map((tcard) => (
            <div className='tcardDiv' key={tcard?.id} data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="50"
              data-aos-offset="0">
              <div className='tcard'>
                <div className='iconDiv pb-3'>{tcard?.icon}</div>
                <h5>{tcard?.head}</h5>
                <p>
                  {tcard?.text}
                </p>
                <Link style={{color:"white"}}  to={tcard?.navigatelink} >  
                <div className='arrowBtn'>
                  <Rightarrow />
                </div>
                </Link>
              </div>
            </div>


          ))}
        </div>
      </div>
    </>
  );
}

export default Technology;
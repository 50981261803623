import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Service.css';
import Herosection from '../Herosection/Herosection';
import Footer from '../Footer/Footer'
// import Counter from '../Counter/counter';
import Technology from '../Technology/Technology';
import Partner from '../Partner/Partner';
import Pricing from '../Pricing/Pricing';
import Navbarfun from '../Navbar/Navbar';


function Service() {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <Navbarfun />
      <div className='service_page'>
        <section>
          <Herosection heading="Services" subheading="Transforming brands!
         Through innovative digital strategies" />
          <section>
            <div className='container-fluid service-price'>
              <Pricing />
            </div>
          </section>
          <section>
            <div className='container-fluid technology'>
              <div className='technology-content' >
                <h6 data-aos="zoom-in-down">WHAT WE OFFER</h6>
                <h1 data-aos="zoom-in-down">Where Strategy Meets Digital. Your Success Story Begins with Social Connect.</h1>
              </div>
              <Technology />
            </div>
          </section>
          {/* <section>
            <div className='container-fluid counter'>
              <Counter />
            </div>
          </section> */}
          {/* <Partner /> */}
          <section>
            <div className='container-fluid footer'>
              <Footer />
            </div>
          </section>
        </section>
      </div >
    </>
  );
}

export default Service;

import React from 'react'
import './Advantage.css'
import RightArrow from '../Rightarrow/RightArrow'

const Advantage = (pops) => {
  return (
    <>
      <div className='advantage-section'>
        <div className='advantageImg'>
        </div>
        <div className='advantage-content-section'>
          <div className='advantage-content'>
            <h1>Leading the way in digital excellence: With Social Connect<br></br>
            </h1>
            <p>Our pioneering approach ensures superior results, innovative strategies, and lasting impact for your brand's journey in the digital landscape.
            </p>
            <RightArrow />
          </div>
        </div>
      </div>
    </>
  )
}

export default Advantage

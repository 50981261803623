import React from 'react'
import './Faq.css'
import Herosection from '../Herosection/Herosection'
import Footer from '../Footer/Footer'
import Navbarfun from '../Navbar/Navbar'
import Accordionfunc from '../Accordian/Accordian'

const Faq = () => {
  return (
    <>
      <Navbarfun />
      <div className='faq_page'>
        <section>
          <Herosection heading="FAQ" subheading="Start the conversation to established good relationship and business." />
          <section>
            <div className='container-fluid faq-section'>
              <div className='row'>
                <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
                  <div className='faq-content'>
                    <h6>COMMON QUESTION</h6>
                    <h1>Most Popular Questions</h1>
                    <button className='faqbtn'>
                      DISCOVER MORE
                    </button>
                  </div>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
                  <div className='faq-question'>
                    <Accordionfunc />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='container-fluid footer'>
              <Footer />
            </div>
          </section>
        </section >
      </div>
    </>
  )
}

export default Faq

import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Pricing.css'
import check from '../../Assets/checkIcon.png'

const Pricing = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <div className='pricing-sec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='pricing-first-content' data-aos="zoom-in-right">
                <h1>Leading the way in Digital Excellence:<br></br> With Social Connect</h1>
                <p>Our pioneering approach ensures superior results, innovative strategies, and lasting impact for your brand's journey in the digital landscape.</p>
                <div className='advantages-list'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Expertise in Digital Solutions</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Innovative Design Approach</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Strategic Digital Marketing</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Data-Driven Decisions</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Holistic Security Measures</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Tailored Digital Strategy</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Responsive & Scalable</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Proven Track Record</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='pricing-second-border' data-aos="zoom-in-up">
                <div className='pricing-second'>
                </div>
              </div>
              <div className='value-arrow-top' data-aos="zoom-in-up">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-up-right right-up-value" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pricing

import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Description.css'
import check from '../../Assets/checkIcon.png'
import stack from '../../Assets/stack.png'
import target from '../../Assets/target.png'
import secure from '../../Assets/secure-shield.png'
import techicon from '../../Assets/technology.png'
import graphic from '../../Assets/graphic-design.png'

const Description = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <div className='pricing-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-8 col-md-12 col-sm-12'>
              <div className='pricing-first-content' data-aos="zoom-in-right">
                <h1>Description</h1>
                <p style={{ border: 'none' }} className='firstpara'>Id blandit facilisis dolor natoque parturient nostra pharetra himenaeos et litora. Himenaeos nascetur vestibulum finibus porttitor ut leo. Finibus commodo class letius feugiat pede montes sociosqu vulputate ornare mattis posuere. Sit nunc purus netus urna letius.</p>
                <p>Id blandit facilisis dolor natoque parturient nostra pharetra himenaeos et litora. Himenaeos nascetur vestibulum finibus porttitor ut leo. Finibus commodo class letius feugiat pede montes sociosqu vulputate ornare mattis posuere. Sit nunc purus netus urna letius.</p>

                <div className='advantages-list'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Expertise in Digital Solutions</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Holistic Security Measures</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Innovative Design Approach</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Tailored Digital Strategy</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Strategic Digital Marketing</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Responsive & Scalable</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='advantage-1'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Expertise in Digital Solutions</span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='advantage-2'>
                        <span>
                          <img src={check} className='check'></img>
                        </span>
                        <span className='check-content'>Holistic Security Measures</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div className='feature-div'>
                <h3>Feature</h3>
                <div className='feature-1'>
                  <img src={stack}></img>
                  <span>UI/UX User Friendly</span>
                </div>
                <div className='feature-2'>
                  <img src={target}></img>
                  <span>Focus On Target</span>
                </div>
                <div className='feature-3'>
                  <img src={secure}></img>
                  <span>Secure App</span>
                </div>
                <div className='feature-4'>
                  <img src={techicon}></img>
                  <span>User Experience Flow</span>
                </div>
                <div className='feature-5'>
                  <img src={graphic}></img>
                  <span>Awesome Graphics Design</span>
                </div>
              </div>
              <div className='descriptionbtn-div'>
                <button className='descriptionBtn'>
                  DOWNLOAD BROCHURE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Description

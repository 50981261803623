import Accordion from 'react-bootstrap/Accordion';
import './Accordian.css'

function Accordionfunc() {
  return (
    <Accordion defaultActiveKey="0" className="accordian" flush>
      <Accordion.Item eventKey="0" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: What services do you offer?</Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: Our comprehensive brand-building services include:

          Brand Strategy and Consulting
          Logo and Identity Design
          Website Design and Development
          Content Creation and Copywriting
          Social Media Management
          Paid Ads
          Graphic Designing
          Digital Marketing Campaigns
          Search Engine Optimisation (SEO)
          Market Research and Analysis
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: What is a brand strategy?
        </Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: A brand strategy is a long-term plan for the development of a successful brand in order to achieve specific goals. It includes defining your brand's mission, vision, values, target audience, competitive positioning, and key messaging.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: How do I get started with Social Connect?
        </Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: Getting started is easy! Simply contact us through our website’s contact form, email, or phone. We’ll schedule a consultation to discuss your needs and how we can help.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: How do you create a digital marketing strategy?
        </Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: We start by understanding your business goals, target audience, and current online presence. Then, we conduct market research and competitor analysis to develop a customized strategy that includes a mix of our services tailored to achieve your specific objectives.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: How can Social Connect help my business?
        </Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: We can help your business grow by increasing your online visibility, building brand, driving more traffic to your website, engaging with your audience on social media, and ultimately converting leads into loyal customers through tailored digital marketing strategies.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: How long does it take to see results?</Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: The timeline for results can vary based on the services provided and your business goals. SEO and content marketing typically take a few months to show significant results, while PPC and social media campaigns can yield quicker outcomes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: How often will I receive reports?</Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: We provide detailed reports on a monthly basis, but we can adjust the frequency based on your preferences. These reports will cover performance metrics, insights, and recommendations for improvement.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: Can you help with website issues or technical problems?</Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: Yes, our team includes web development experts who can assist with website issues, technical problems, and updates to ensure your online presence remains strong and effective.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8" className="accordion-item">
        <Accordion.Header className='accor-head'>Q: Are there any hidden fees?
        </Accordion.Header>
        <Accordion.Body className='accor-body'>
          A: No, we believe in transparency. All costs will be clearly outlined in your proposal, and there are no hidden fees. Any additional expenses will be discussed and approved by you beforehand.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Accordionfunc;

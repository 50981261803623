import React, { useEffect, useState } from 'react';
import digitalImg from '../../Assets/DigitalImg.png';
import AOS from "aos";
import "aos/dist/aos.css";
import './Digital.css'

const Digital = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <div className='container-fluid digital-future'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='digitalImgDiv' data-aos="zoom-in-right">
              <img src={digitalImg} className={`digitalImg ${hovered ? 'hovered' : ''}`} alt="Digital" onMouseEnter={handleHover} onMouseLeave={handleHover} />
              <div className='circle'>
                <svg
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  width={100}
                  height={100}
                  color='white'
                >
                  <path
                    className='innerCircle'
                    id="circlePath"
                    d="
      M 10, 50
      a 40,40 0 1,1 80,0
      40,40 0 1,1 -80,0
    "
                    style={{ fill: hovered ? 'rgba(180, 124, 253, 0.8)' : 'transparent', stroke: 'white', strokeWidth: 1 }}
                  />
                  <text>
                    <textPath href="#circlePath" fontSize={15} className='circleText' style={{ fill: 'white' }}>
                      PREMIUM SERVICES & SUPPORT
                    </textPath>
                  </text>
                </svg>
              </div>
            </div>
          </div>
          <div className='col-lg-6' data-aos="zoom-in-up">
            <div className='digital-content'>
              <h6>WHO WE ARE</h6>
              <h1>Your Digital Future, Our Expertise: Social Connect Delivers Excellence.</h1>
              <p>We're a bunch of digital enthusiasts who love what we do. From tech whizzes to creative minds, our team is a mix of different talents, all focused on making your brand look awesome online.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Digital

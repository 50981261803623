import React from 'react'
import './Teamcard.css'
import teamimg1 from '../../Assets/teamimg1.jpg'
import teamimg2 from '../../Assets/teamimg2.jpg'
import teamimg3 from '../../Assets/teamimg3.jpg'
import teamimg4 from '../../Assets/teamimg4.jpg'


const Teamcard = () => {
  return (
    <>
      <section>
        <div className='container-fluid'>
          <div className='teamcard-section'>
            <div className='team-content'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12 tcontent-h1'>
                  <h1> Meet our team</h1>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-12 tcontent-p'>
                  <p>Alone we can do so little, together we <br></br>can do so much.</p>
                </div>
              </div>
            </div>
            <div className='card-section container-fluid'>
              <div className='row cardrow'>
                <div className='card card1 col-xxl-6 col-lg-4'>
                  <img src={teamimg1} className='teamimg'></img>
                  <h4>Founder</h4>
                  {/* <h6>Co-Founder</h6>
                  <h6>Business Manager</h6>
                  <h6>HRM</h6> */}
                  <div className='social-media'>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={16} width={16}><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-twitter" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                      </svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                    </div>

                  </div>
                </div>
                <div className='card card2 col-xxl-6 col-lg-4'>
                  <img src={teamimg2} className='teamimg'></img>
                  {/* <h4>Founder</h4> */}
                  <h4>Co-Founder</h4>
                  {/* <h6>Business Manager</h6>
                  <h6>HRM</h6> */}
                  <div className='social-media'>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={16} width={16}><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-twitter" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                      </svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                    </div>

                  </div>
                </div>
                <div className='card card3 col-xxl-6 col-lg-4'>
                  <img src={teamimg3} className='teamimg'></img>
                  {/* <h4>Founder</h4>
                  <h6>Co-Founder</h6> */}
                  <h4>Business Manager</h4>
                  {/* <h6>HRM</h6> */}
                  <div className='social-media'>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={16} width={16}><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-twitter" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                      </svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                    </div>
                  </div>
                </div>
                <div className='card card4 col-xxl-6 col-lg-4'>
                  <img src={teamimg4} className='teamimg'></img>
                  <h4>HRM</h4>
                  {/* <h6>Co-Founder</h6>
                  <h6>Business Manager</h6>
                  <h6>HRM</h6> */}
                  <div className='social-media'>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height={16} width={16} className=''><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-twitter" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                      </svg>
                    </div>
                    <div className='social-media-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16}><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Teamcard

import React, { useEffect, useRef } from 'react';
import './counter.css';

const Counter = () => {
  const counterRef = useRef(null);

  useEffect(() => {
    const counts = document.querySelectorAll('.count');

    const startCounting = (entry) => {
      if (!entry.isIntersecting) return;

      counts.forEach((item) => {
        let startNumber = 0;
        const targetNumber = parseInt(item.dataset.number.replace('+', ''), 10);
        const isPlus = item.dataset.number.includes('+');

        const counterUp = () => {
          startNumber++;
          item.innerHTML = isPlus ? `${startNumber}+` : startNumber;

          if (startNumber === targetNumber) {
            clearInterval(stop);
          }
        };

        const stop = setInterval(() => {
          counterUp();
        }, 50);
      });
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(startCounting);
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  return (
    <div id="counter" ref={counterRef}>
      <div className="item">
        <h1 className="count" data-number="500+">
        </h1>
        <p className="text">PROJECTS DONE</p>
      </div>
      <div className="item">
        <h1 className="count" data-number="300+">
        </h1>
        <p className="text">HAPPY CLIENT</p>
      </div>
      <div className="item">
        <h1 className="count" data-number="4.8"></h1>
        <p className="text">CLIENT RATINGS</p>
      </div>
      <div className="item">
        <h1 className="count" data-number="100+"></h1>
        <p className="text">NO. OF INDUSTRY</p>
      </div>

    </div>
  );
};

export default Counter;

import React from 'react'
import './Aboutus.css'
import Herosection from '../Herosection/Herosection'
import Digital from '../DigitalFuture/Digital'
import Value from '../Value/Value'
import Counter from '../Counter/counter'
import Footer from '../Footer/Footer'
import Teamcard from '../Teamcard/Teamcard'
import Navbarfun from '../Navbar/Navbar'

const About = () => {
  return (
    <>
      <Navbarfun />
      <div className='container-fluid about_page'>
        <Herosection heading="About Us" subheading="We Develop & Create Digital Future" />
        <Digital />
        <Value valueslogan="Our Approach" head="We're a bunch of digital enthusiasts who love what we do." para="From tech whizzes to creative minds, our team is a mix of different talents, all focused on making your brand look awesome online." valueSecond1slogan="Collaboration:" valueSecond1head="We believe in the power of collaboration. " valueSecond1para="We work closely with our clients, ensuring that every campaign aligns seamlessly with your brand identity and business objectives."
          valueSecond2slogan="Transparency:" valueSecond2head="Open communication is the cornerstone of our client relationships. " valueSecond2para="We maintain complete transparency throughout the entire process, from project planning to execution and reporting. You'll always know how your digital marketing efforts are performing." />
        <section>
          <div className='container-fluid counter'>
            <Counter />
          </div>
        </section>
        {/*<Teamcard />*/}
        <section>
          <div className='container-fluid footer'>
            <Footer />
          </div>
        </section>

      </div>
    </>
  )
}

export default About;

import React from 'react'
import './DetailService.css'
import Footer from '../Footer/Footer'
import Webdevelopment from '../Webdevelopment/webdevelopment'
import Step from '../Step Section/Step'
import Advantage from '../../components/Advantage-section/Advantage'
import Testimonial from '../Testimonial/Testimonial'
import Description from '../Description/Description'
import Pricingcard from '../PricingCard/Pricingcard'
import Navbarfun from '../Navbar/Navbar'

const DetailService = () => {
  return (
    <>
      <Navbarfun />
      <div className='detailservice_page'>
        <section>
          <Webdevelopment />
          <div className='container-fluid description'>
            <Description />
          </div>
          <section>
            <div className='pricing-card-content'>
              <p>PRICING PLAN</p>
              <h1>
                Predictable Pricing. No Surprises.
              </h1>
              <h5>Great value at an affordable rate. No hidden costs. No frills.</h5>
            </div>
            <div className='container-fluid price-card-sec'>
              <div className='row'>
                <div className='col-lg-4 col-md-12'>
                  <Pricingcard />
                </div>
                <div className='col-lg-4 col-md-12'>
                  <Pricingcard />
                </div>
                <div className='col-lg-4 col-md-12'>
                  <Pricingcard />
                </div>
              </div>
            </div>
          </section>
          <Step />
          <Advantage />
          <Testimonial />
          <section>
            <div className='container-fluid footer'>
              <Footer />
            </div>
          </section>
        </section>
      </div>

    </>
  )
}

export default DetailService;

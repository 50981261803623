import React from 'react'
import './Pricingcard.css'

const Pricingcard = () => {
  return (
    <>
      <div className='pricing-card'>
        <h3>Stater Pack</h3>
        <p className='paragraph'>Inceptos tempus libero faucibus et primis potenti maecenas massa duis maximus eget</p>
        <div className='price'>
          <h2>$49</h2>
          <p> /MONTHLY</p>
        </div>
        <div className='include-list'>
          <h5>Includes:</h5>
          <ul>
            <li>
              Pulvinar donec habitant ridiculus
            </li>
            <li>
              Habitant condimentum nulla si
            </li>
            <li>
              Ut congue letius lectus
            </li>
            <li>
              Pharetra morbi nisi quam primis turpis
            </li>
            <li>
              Tempor sagittis laoreet urna
            </li>
          </ul>
        </div>
        <button className='purchaseBtn'>Purchase Order</button>
        <p className='price-line'>*Massa posuere augue nibh eget nam senectus sed pulvinar ullamcorper</p>

      </div>
    </>
  )
}

export default Pricingcard

import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Step.css'

const Step = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <section className='container-fluid step-section'>
        <div className='step-section-content'>
          <div className='step-head'>
            <h6>OUR VALUES</h6>
            <h1>
              Our Services Make Your Work Productive
            </h1>
          </div>
        </div>
        <div className='step-container'>
          <div className='row'>
            <div className='col-lg-3 col-md-6  col-sm-6 stepDiv stepDiv-1' data-aos="zoom-in-up">
              <div className='icon-circle'>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" fill="currentColor" class="bi bi-chat chat-icon" viewBox="0 0 16 16" color='white'>
                  <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105" />
                </svg>
              </div>
              <h6>Innovation</h6>
              <p>We don't just follow trends; we set them. Our team is at the forefront of digital innovation</p>
            </div>
            <div className='col-lg-3 col-md-6  col-sm-6 stepDiv stepDiv-2' data-aos="zoom-in-up">
              <div className='icon-circle'>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" fill="currentColor" class="bi bi-pc-display" viewBox="0 0 16 16" color='white'>
                  <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0m2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0M9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5M1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2z" />
                </svg>
              </div>
              <h6>Customization</h6>
              <p>We understand that each business is unique. No one-size-fits-all approaches here – just personalized strategies for success.</p>
            </div>
            <div className='col-lg-3 col-md-6  col-sm-6 stepDiv stepDiv-3' data-aos="zoom-in-down">
              <div className='icon-circle'>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" fill="currentColor" class="bi bi-stack" viewBox="0 0 16 16" color='white'>
                  <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                  <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                </svg>
              </div>
              <h6>Implementation </h6>
              <p>At Social Connect,we execute digital marketing strategies with precision, dedication, and efficiency, ensuring tangible results for our Clients.</p>
            </div>
            <div className='col-lg-3 col-md-6  col-sm-6 stepDiv-4'>
              <div className='stepDiv step4' data-aos="zoom-in-down">
                <div className='icon-circle'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" fill="currentColor" class="bi bi-chat chat-icon" viewBox="0 0 16 16" color='white'>
                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105" />
                  </svg>
                </div>
                <h6>Result- Driven</h6>
                <p>Your success is our success. We are obsessed with delivering measurable results that impact your bottom line.</p>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default Step

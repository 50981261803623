import React from 'react'
import './SingalPost.css'
import Navbarfun from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import spimg from '../../Assets/website.jpg'


const Singal = (props) => {
  return (
    <>
      <Navbarfun />
      <div className='container-fluid singal_page'>
        <section>
          <div className='container-fluid first-sec'>
            <h1>{props.head1}</h1>
            <h6>{props.head6}</h6>
          </div>
        </section>
        <section>
          <div className='container-fluid singal-content'>
            <div className='row'>
              <div className='col-lg-4 col-md-12 col-sm-12'>
                <div className='spimg'>
                  <img src={spimg}></img>
                </div>
              </div>
              <div className='col-lg-8 col-md-12 col-sm-12'>
                <div className='spimg-content'>
                  <p>{props.flpara}</p>
                  <h5>{props.detail} </h5>
                  <h6>{props.detailh5}</h6>
                  <h6>{props.des}</h6>
                  <p>{props.detailpara}</p>
                  <h6>{props.q1}</h6>
                  <h6>{props.q2}</h6>
                  <h6>{props.q3}</h6>
                  <h6>{props.q4}</h6>
                  <h6>{props.q5}</h6>
                  <h6>{props.q6}</h6>
                  <h6>{props.q7}</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container-fluid footer'>
            <Footer />
          </div>
        </section>
      </div>
    </>
  )
}

export default Singal;

import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './Webdevelopment.css'
import desktop from '../../Assets/imac_img.png'

const Webdevelopment = () => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
  };
  return (
    <>
      <div className='container-fluid development-future'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 development-content' data-aos="zoom-in-right">
            <h6>WEBDEVELOPMENT</h6>
            <h1>Delivering IT solutions that enable you to work smarter.</h1>
            <p>At ligula justo morbi sagittis fusce. Accumsan magna habitant non habitasse litora etiam torquent. Placerat lobortis volutpat efficitur elementum sagittis leo amet euismod tristique.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <div className='web-Img' data-aos="zoom-in-left">
              <img src={desktop}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Webdevelopment

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';
import Portfolio from './components/Portfolio/Portfolio'
import Contact from './components/Contactus/Contact'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import About from './components/About/AboutUs';
import Service from './components/Service/Service';
import DetailService from './components/DetailService/DetailService';
import Faq from './components/FAQ/Faq';
import SingalPost from './components/SingalPost/SingalPost'
import SocialMedia from './components/SocialMedia/SocialMedia';
import Branding from './components/Branding/Branding';
import Teamcard from './components/Teamcard/Teamcard';
import Lg from './components/Lg/Lg';
import Gd from './components/GraphicDesign/GraphicDesign';
import Seo from './components/Seo/Seo';
import Webdev from './components/WebsiteDevelopment/WebDev';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Service />} />
        <Route path="/detailservice" element={<DetailService />} />
        <Route path="/SingalPost" element={<SingalPost />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/socialmedia" element={<SocialMedia />} />
        <Route path="/branding" element={<Branding />} />
        <Route path="/teamcard" element={<Teamcard />} />
        <Route path="/lg" element={<Lg />} />
        <Route path="/gd" element={<Gd />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/webdev" element={<Webdev />} />
        {/* <Route path="/seo" element={<Seo />} /> */}

      </Routes>
    </Router>
  );
}

export default App;
import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonial.css'
import Slider from 'react-slick';
import profile2 from '../../Assets/profile2.png'
import profile3 from '../../Assets/profile3.png'

function Testimonial() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Set autoplay to true
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    dotsClass: 'slick-dots white-dots', // Add a custom class for styling
    customPaging: function (i) { // Customize dot indicators
      return (
        <button className="white-dot"></button>
      );
    },
    responsive: [
      {
        breakpoint: 1024, // Laptop
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768, // Tablet
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480, // Small devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className='testimonial-content'>
        <h6>TESTIMONIAL</h6>
        <h1>Client Feedback & Reviews</h1>
      </div>
      <div className='testimonial-slider'>
        <div className="my-slider">
          <Slider {...settings}>
            <div className='slide'>
             {/* <img src={profile2} alt="profile" />*/}
              <p>
                •	"Social Connect transformed our online presence. Their tailored strategy significantly boosted our lead generation. They truly care about our success!"
              </p>
              <h6>DilKiya</h6>
             {/* <h5 className='cname'>Bright Electronics</h5>*/}
            </div>
            <div className='slide'>
             {/* <img src={profile3} alt="profile" />*/}
              <p>
                "Thanks to Social Connect, our social media is thriving. Their creative content and engagement have built a loyal customer base and driven our sales up. They're simply amazing!"
              </p>
              <h6>Mother Choice</h6>
            {/*  <h5 className='cname'>Bright Electronics</h5>*/}
            </div>
            <div className='slide'>
            {/*  <img src={profile2} alt="profile" />*/}
              <p>
                "Social Connect has taken our brand to new heights. Their SEO and social media campaigns have delivered outstanding results. We appreciate their dedication and regular updates."
              </p>
              <h6>Heeriye</h6>
             {/* <h5 className='cname'>Bright Electronics</h5>*/}
            </div>
            <div className='slide'>
             {/* <img src={profile3} alt="profile" />*/}
              <p>
                "I can’t thank Social Connect enough. Their team revamped our website and managed our social media, leading to increased traffic and sales. They’re genuinely passionate about our success."
              </p>
              <h6>Drymart</h6>
             {/* <h5 className='cname'>Bright Electronics</h5>*/}
            </div>
            <div className='slide'>
            {/*  <img src={profile2} alt="profile" />*/}
              <p>
                "Partnering with Social Connect was the best decision we made. Their innovative strategies have boosted our brand visibility and online engagement. Truly exceptional service!"
              </p>
              <h6>4Edges</h6>
             {/* <h5 className='cname'>Bright Electronics</h5>*/}
            </div>
          </Slider>
        </div>
      </div>
    </>
  )
}

export default Testimonial
